:root {
  --navigation-height: 64px;
  --sidebar-width: 280px;
}

@media (min-width: 820px) {
  :root {
    --navigation-height: 72px;
  }
}

@media (min-width: 1100px) {
  :root {
    --sidebar-width: 365px;
  }
}

body.ReactModal__Body--open {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.backdrop > .layer {
  background: rgba(245, 241, 236, 0.5) !important;
  opacity: 1 !important;
  -webkit-backdrop-filter: blur(8px) !important;
  -webkit-backdrop-filter: blur(8px) !important;
  backdrop-filter: blur(8px) !important;
}

.ReactModal__Overlay--before-close .ReactModal__Content {
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0);
  opacity: 0;
}

.qwary-panel-wrap {
  right: 0 !important;
}

.qwary-panel-wrap iframe {
  background: linear-gradient(
    345deg,
    rgb(231, 164, 229),
    rgb(249, 241, 214)
  ) !important;
  background-size: 200% 200% !important;
}

.qwary-panel {
  height: auto !important;
}

.qwary-side-panel {
  height: 240px !important;
  width: 0px !important;
}

.qwary-close {
  top: 3px !important;
  right: 7px !important;
}

.qwary-lunch-btn {
  top: 0;
}

@media screen and (min-width: 500px) {
  .qwary-close {
    right: 16px !important;
  }
}

/* Hides the OneTrust Cookie Widget */
.ot-floating-button__back,
.ot-floating-button__front,
#ot-sdk-btn-floating {
  display: none;
}

/* Hides the OneTrust Cookie Consent Banner */
#onetrust-banner-sdk {
  display: none;
}

.recharts-surface {
  overflow: visible;
}

.addToCalendar {
  z-index: 1000 !important;
}
